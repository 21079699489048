@import url("https://fonts.googleapis.com/css2?family=Kavoon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lakki+Reddy&display=swap");
// Rest css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Kavoon", sans-serif;
  font-size: 16px;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}
// Style
.App {
  background-color: #000;
  background-image: url("./assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  .container {
    padding-left: 180px;
    padding-right: 180px;
    @media only screen and (max-width: 850px) {
      padding-left: 80px;
      padding-right: 80px;
    }
    @media only screen and (max-width: 650px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .page-header {
    .container {
      margin: 0 auto;
      height: 83px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 160px;
      @media only screen and (max-width: 1170px) {
        gap: 80px;
      }
      @media only screen and (max-width: 940px) {
        gap: 40px;
      }
      @media only screen and (max-width: 850px) {
        flex-direction: column;
        height: auto;
        padding: 12px 0;
        gap: 10px;
      }
      .logo-app {
        flex-shrink: 0;
        width: 72px;
      }
      .nav-menu {
        display: flex;
        align-items: center;
        gap: 80px;
        @media only screen and (max-width: 1170px) {
          gap: 40px;
        }
        @media only screen and (max-width: 940px) {
          gap: 20px;
        }
        @media only screen and (max-width: 440px) {
          flex-direction: column;
          gap: 12px;
        }
        li {
          color: #000;
          text-align: center;
          font-size: 30px;
          @media only screen and (max-width: 440px) {
            font-size: 24px;
          }
        }
      }
      .btn-buy-now {
        flex-shrink: 0;
        border-radius: 10px;
        border: 2px solid #000;
        color: #000;
        padding: 10px 30px;
        text-align: center;
        font-size: 30px;
        @media only screen and (max-width: 440px) {
          font-size: 24px;
          padding: 10px 20px;
        }
      }
    }
  }
  .page-main {
    .section-intro {
      padding-top: 180px;
      @media only screen and (max-width: 1200px) {
        padding-top: 150px;
      }
      @media only screen and (max-width: 650px) {
        padding-top: 70px;
      }
      @media only screen and (max-width: 568px) {
        padding-top: 30px;
      }
      .container {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 49px;
        @media only screen and (max-width: 1200px) {
          flex-wrap: wrap;
        }
        @media only screen and (max-width: 568px) {
          gap: 29px;
        }
        .content {
          @media only screen and (max-width: 1200px) {
            text-align: center;
          }
          .title-1 {
            color: #01a711;
            -webkit-text-stroke: 1px white;
            font-family: Lakki Reddy;
            font-size: 140px;
            text-transform: uppercase;
            line-height: 24px;
            @media only screen and (max-width: 1710px) {
              line-height: 120px;
              margin-bottom: -40px;
            }
            @media only screen and (max-width: 1400px) {
              font-size: 100px;
              line-height: 100px;
            }
            @media only screen and (max-width: 568px) {
              font-size: 80px;
              line-height: normal;
            }
            @media only screen and (max-width: 440px) {
              font-size: 60px;
            }
          }
          .title-2 {
            font-family: Lakki Reddy;
            -webkit-text-stroke: 1px white; /* Tạo viền màu trắng với độ dày là 1px (Prefix -webkit- cho trình duyệt Safari/Chrome) */
            font-size: 140px;
            text-transform: uppercase;
            background: linear-gradient(99deg, #ff01b8 13.85%, #0085ff 97.73%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media only screen and (max-width: 1710px) {
              margin-bottom: -60px;
            }
            @media only screen and (max-width: 1400px) {
              font-size: 100px;
            }
            @media only screen and (max-width: 568px) {
              font-size: 80px;
              line-height: normal;
              margin-bottom: -20px;
            }
            @media only screen and (max-width: 440px) {
              font-size: 60px;
            }
          }
          .description,
          .sub-description {
            color: #fff;
            font-size: 30px;
            @media only screen and (max-width: 568px) {
              font-size: 24px;
            }
            @media only screen and (max-width: 375px) {
              font-size: 18px;
            }
          }
          .sub-description {
            padding-top: 31px;
            @media only screen and (max-width: 568px) {
              padding-top: 21px;
            }
            @media only screen and (max-width: 375px) {
              font-size: 18px;
            }
          }
        }
        .img-intro {
          width: 628px;
          flex-shrink: 0;
          @media only screen and (max-width: 768px) {
            flex-shrink: unset;
          }
        }
      }
    }
    .section-about {
      padding-top: 255px;
      @media only screen and (max-width: 1200px) {
        padding-top: 150px;
      }
      @media only screen and (max-width: 650px) {
        padding-top: 70px;
      }
      @media only screen and (max-width: 568px) {
        padding-top: 30px;
      }
      .container {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .box-wrapper {
          border-radius: 30px;
          border: 5px solid #fff;
          background: rgba(217, 217, 217, 0.3);
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          padding-top: 60px;
          padding-bottom: 49px;
          .content {
            padding: 0 140px;
            @media only screen and (max-width: 1200px) {
              padding: 0 80px;
            }
            @media only screen and (max-width: 940px) {
              padding: 0 20px;
            }
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
              color: #ccf951;
              font-family: Lakki Reddy;
              font-size: 100px;
              text-transform: uppercase;
              @media only screen and (max-width: 1405px) {
                font-size: 60px;
              }
            }
            .description {
              color: #fff;
              text-align: center;
              font-size: 30px;
              @media only screen and (max-width: 440px) {
                font-size: 24px;
              }
              @media only screen and (max-width: 375px) {
                font-size: 18px;
              }
            }
          }
          .img-about {
            position: absolute;
            right: 0;
            top: 0;
            width: 367px;
            flex-shrink: 0;
            z-index: 0;
            @media only screen and (max-width: 440px) {
              flex-shrink: unset;
              width: auto;
            }
          }
          .btn-buy-now {
            border-radius: 10px;
            border: 2px solid #000;
            background: #fff;
            padding: 10px 30px;
            margin-top: 20px;
            color: #000;
            text-align: center;
            font-size: 30px;
            @media only screen and (max-width: 440px) {
              font-size: 24px;
              padding: 10px 20px;
            }
          }
        }
      }
    }
    .section-tokenomics {
      padding-top: 277px;
      @media only screen and (max-width: 1200px) {
        padding-top: 150px;
      }
      @media only screen and (max-width: 650px) {
        padding-top: 70px;
      }
      @media only screen and (max-width: 568px) {
        padding-top: 30px;
      }
      .container {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 1180px) {
          flex-wrap: wrap;
        }
        .img-tokenomics {
          width: 760px;
          flex-shrink: 0;
          margin-right: -100px;
          z-index: 1;
          @media only screen and (max-width: 1180px) {
            margin-right: unset;
          }
          @media only screen and (max-width: 940px) {
            flex-shrink: unset;
          }
        }
        .content {
          border-radius: 100px 0px;
          border: 5px solid #fff;
          background: rgba(217, 217, 217, 0.3);
          padding: 40px 50px;
          width: fit-content;
          display: flex;
          align-items: center;
          flex-direction: column;
          @media only screen and (max-width: 1460px) {
            padding: 30px 40px;
          }
          @media only screen and (max-width: 1180px) {
            padding: 15px 20px;
          }
          .title {
            color: #ccf951;
            font-family: Lakki Reddy;
            font-size: 100px;
            text-transform: uppercase;
            @media only screen and (max-width: 1460px) {
              font-size: 60px;
            }
            @media only screen and (max-width: 510px) {
              font-size: 40px;
            }
          }
          .description {
            color: #fff;
            text-align: center;
            font-size: 30px;
            padding-bottom: 30px;
            @media only screen and (max-width: 510px) {
              font-size: 24px;
            }
            @media only screen and (max-width: 375px) {
              font-size: 18px;
            }
          }
          .btn-check-it-out {
            color: #000;
            text-align: center;
            font-size: 30px;
            border-radius: 10px;
            border: 2px solid #000;
            background: #fff;
            padding: 10px 30px;
            @media only screen and (max-width: 440px) {
              font-size: 24px;
              padding: 10px 20px;
            }
          }
        }
      }
    }
    .section-community {
      padding-top: 196px;
      padding-bottom: 151px;
      @media only screen and (max-width: 1200px) {
        padding-top: 150px;
        padding-bottom: 110px;
      }
      @media only screen and (max-width: 650px) {
        padding-top: 70px;
        padding-bottom: 60px;
      }
      @media only screen and (max-width: 568px) {
        padding-top: 50px;
        padding-bottom: 30px;
      }
      .container {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .title {
          color: #01a711;
          -webkit-text-stroke: 1px white;
          font-family: Lakki Reddy;
          font-size: 100px;
          text-transform: uppercase;
          line-height: 58px;
          @media only screen and (max-width: 780px) {
            line-height: 84px;
            text-align: center;
          }
          @media only screen and (max-width: 568px) {
            font-size: 80px;
            line-height: 70px;
          }
          @media only screen and (max-width: 440px) {
            font-size: 60px;
          }
        }
        .description {
          color: #fff;
          text-align: center;
          font-size: 30px;
          @media only screen and (max-width: 510px) {
            font-size: 24px;
          }
          @media only screen and (max-width: 375px) {
            font-size: 18px;
          }
        }
        .socials {
          display: flex;
          align-items: center;
          gap: 53px;
          padding-top: 34px;
          @media only screen and (max-width: 510px) {
            padding-top: 18px;
          }
        }
      }
    }
  }
  .page-footer {
    padding-top: 19px;
    padding-bottom: 43px;
    background: #000;
    .container {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .img-footer {
        width: 148px;
      }
      p {
        padding-top: 23px;
        color: #fff;
        text-align: center;
        font-size: 30px;
        @media only screen and (max-width: 780px) {
          font-size: 24px;
        }
        @media only screen and (max-width: 375px) {
          font-size: 18px;
        }
      }
    }
  }
}
